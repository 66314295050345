<div class="dialog-wrapper">
  <div class="text-font-light wrap-anywhere">
    {{ errorMessage$ | async }}
  </div>
  <div class="text-font-light mt-4">
    <button class="btn secondary outline" i18n (click)="submitted.emit()">
      Reset password
    </button>
  </div>
</div>
