import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

import { EventDispatcher } from '../event-dispatcher/event-dispatcher';
import {
  APPOINTMENT_CREATION_EVENT,
  AppointmentCreationEvent,
} from '../event-dispatcher/events/appointment-creation-event';
import {
  BSG_AUTHENTICATED_PAGEVIEW_EVENT,
  BsgPageviewAuthenticatedEvent,
} from '../event-dispatcher/events/bsg-pageview-authenticated-event';
import {
  BSG_UNAUTHENTICATED_PAGEVIEW_EVENT,
  BsgPageviewUnauthenticatedEvent,
} from '../event-dispatcher/events/bsg-pageview-unauthenticated-event';
import {
  LOGIN_EVENT,
  LoginEvent,
} from '../event-dispatcher/events/login-event';
import { LOGOUT_EVENT } from '../event-dispatcher/events/logout-event';
import {
  SIGNUP_EVENT,
  SignupEvent,
} from '../event-dispatcher/events/signup-event';
import {
  SIGNUP_VERIFICATION_EVENT,
  SignupVerificationEvent,
} from '../event-dispatcher/events/signup-verification-event';
import {
  SIGNUP_VERIFICATION_SUCCESS_EVENT,
  SignupVerificationSuccessEvent,
} from '../event-dispatcher/events/signup-verification-success-event';
import {
  VALIDATE_TIMESLOT_EVENT,
  ValidateTimeslotEvent,
} from '../event-dispatcher/events/validate-timeslot-event';
import { Sha256Service } from './sha-256.service';

const AUTHENTICATED = 1;
const UNAUTHENTICATED = 0;

@Injectable()
export class AnalyticsListener {
  constructor(
    private readonly eventDispatcher: EventDispatcher,
    private readonly gtmService: GoogleTagManagerService,
    private readonly sha256Service: Sha256Service,
  ) {}

  init(): void {
    this.eventDispatcher.subscribe(LOGIN_EVENT, (e) =>
      this.trackLogin(e as LoginEvent),
    );
    this.eventDispatcher.subscribe(SIGNUP_VERIFICATION_EVENT, (e) =>
      this.trackSignupVerification(e as SignupVerificationEvent),
    );
    this.eventDispatcher.subscribe(APPOINTMENT_CREATION_EVENT, (e) =>
      this.trackAppointmentCreation(e as AppointmentCreationEvent),
    );
    this.eventDispatcher.subscribe(BSG_AUTHENTICATED_PAGEVIEW_EVENT, (e) =>
      this.trackAuthenticatedBsgPageview(e as BsgPageviewAuthenticatedEvent),
    );
    this.eventDispatcher.subscribe(BSG_UNAUTHENTICATED_PAGEVIEW_EVENT, (e) =>
      this.trackUnauthenticatedBsgPageview(
        e as BsgPageviewUnauthenticatedEvent,
      ),
    );
    this.eventDispatcher.subscribe(LOGOUT_EVENT, this.trackLogout.bind(this));
    this.eventDispatcher.subscribe(SIGNUP_EVENT, (e) =>
      this.trackSignup(e as SignupEvent),
    );
    this.eventDispatcher.subscribe(SIGNUP_VERIFICATION_SUCCESS_EVENT, (e) =>
      this.trackSignupVerificationSuccess(e as SignupVerificationSuccessEvent),
    );
    this.eventDispatcher.subscribe(VALIDATE_TIMESLOT_EVENT, (e) =>
      this.trackValidateTimeslotEvent(e as ValidateTimeslotEvent),
    );
  }

  trackSignupVerificationSuccess(event: SignupVerificationSuccessEvent) {
    this.sha256Service
      .calculateSHA256HashPromiseObject({
        i: event.payload.userId ?? '',
        idsh: event.payload.patientUuid ?? '',
        psh: event.payload.phone ?? '',
        esh: event.payload.email ?? '',
      })
      .then((response) => {
        this.gtmService
          .pushTag({
            event: 'sign_up_verif_success',
            ud: {
              l: AUTHENTICATED,
              ...response,
              id: event.payload.patientUuid ?? '',
              e: event.payload.email ?? '',
              p: event.payload.phone ?? '',
            },
          })
          .catch(this.rethrowIfNotDueToAnalyticsNotLoaded);
      });
  }

  trackValidateTimeslotEvent(event: ValidateTimeslotEvent) {
    this.gtmService.pushTag({
      event: 'click_validate_timeslot',
      category_id: event.payload.categoryId,
      category_name: event.payload.categoryName,
      motif_id: event.payload.motiveId,
      motif_name: event.payload.motiveName,
      center: event.payload.medicalCenterName,
    });
  }

  private trackLogin(event: LoginEvent) {
    this.sha256Service
      .calculateSHA256HashPromiseObject({
        i: event.payload.userId,
        idsh: event.payload.patientUuid ?? '',
        esh: event.payload.email ?? '',
        psh: event.payload.phone ?? '',
        fnsh: event.payload.firstName,
        lnsh: event.payload.lastName,
        gsh: event.payload.gender?.charAt(0).toLowerCase(),
        ctsh: event.payload.city,
        pcsh: event.payload.postalCode,
        cosh: event.payload.country,
      })
      .then((response) => {
        this.gtmService
          .pushTag({
            event: 'login',
            method: 'Email',
            ud: {
              l: AUTHENTICATED,
              ...response,
              id: event.payload.patientUuid ?? '',
              e: event.payload.email ?? '',
              p: event.payload.phone ?? '',
              fn: event.payload.firstName,
              ln: event.payload.lastName,
              g: event.payload.gender?.charAt(0).toLowerCase(),
              ct: event.payload.city,
              pc: event.payload.postalCode,
              co: event.payload.country,
            },
          })
          .catch(this.rethrowIfNotDueToAnalyticsNotLoaded);
      });
  }

  private trackLogout() {
    this.gtmService
      .pushTag({
        event: 'logout',
        ud: {
          l: UNAUTHENTICATED,
        },
      })
      .catch(this.rethrowIfNotDueToAnalyticsNotLoaded);
  }

  private trackSignupVerification(event: SignupVerificationEvent) {
    this.sha256Service
      .calculateSHA256HashPromiseObject({
        i: event.payload.userId,
        idsh: event.payload.patientUuid ?? '',
        esh: event.payload.email,
        psh: event.payload.phone,
      })
      .then((response) => {
        this.gtmService
          .pushTag({
            event: 'sign_up_verif',
            ud: {
              l: AUTHENTICATED,
              ...response,
              id: event.payload.patientUuid ?? '',
              e: event.payload.email,
              p: event.payload.phone,
            },
          })
          .catch(this.rethrowIfNotDueToAnalyticsNotLoaded);
      });
  }

  private trackAppointmentCreation(event: AppointmentCreationEvent) {
    this.sha256Service
      .calculateSHA256HashPromiseObject({
        i: event.payload.userId,
        aish: event.payload.appointmentId,
        idsh: event.payload.patientUuid,
        esh: event.payload.email ?? '',
        psh: event.payload.phone ?? '',
        fnsh: event.payload.firstName,
        lnsh: event.payload.lastName,
        gsh: event.payload.gender?.charAt(0).toLowerCase(),
        ctsh: event.payload.city,
        pcsh: event.payload.postalCode,
        cosh: event.payload.country,
      })
      .then((response) => {
        this.gtmService
          .pushTag({
            event: 'appt_confirmed',
            timestamp: event.payload.timestamp,
            appointment_timestamp: event.payload.appointment_timestamp,
            appointment_day: event.payload.appointment_day,
            appointment_time: event.payload.appointment_time,
            category_id: event.payload.categoryId,
            category_name: event.payload.categoryName,
            motif_id: event.payload.motiveId,
            motif_name: event.payload.motiveName,
            center: event.payload.medicalCenterName,
            ud: {
              l: AUTHENTICATED,
              ...response,
              id: event.payload.patientUuid,
              ai: event.payload.appointmentId,
              e: event.payload.email ?? '',
              p: event.payload.phone ?? '',
              fn: event.payload.firstName,
              ln: event.payload.lastName,
              g: event.payload.gender?.charAt(0).toLowerCase(),
              ct: event.payload.city,
              pc: event.payload.postalCode,
              co: event.payload.country,
            },
          })
          .catch(this.rethrowIfNotDueToAnalyticsNotLoaded);
      });
  }

  private trackAuthenticatedBsgPageview(event: BsgPageviewAuthenticatedEvent) {
    this.sha256Service
      .calculateSHA256HashPromiseObject({
        i: event.payload.userId,
        idsh: event.payload.patientUuid,
        esh: event.payload.email,
        psh: event.payload.phone,
        fnsh: event.payload.firstName,
        lnsh: event.payload.lastName,
        gsh: event.payload.gender.charAt(0).toLowerCase(),
        ctsh: event.payload.city,
        pcsh: event.payload.postalCode,
        cosh: event.payload.country,
      })
      .then((response) => {
        this.gtmService
          .pushTag({
            event: 'bsg_pageview',
            lang: event.payload.lang,
            site: event.payload.site,
            page: {
              path: event.payload.path,
            },
            mc_name: event.payload.mc_name,
            ud: {
              l: AUTHENTICATED,
              ...response,
              id: event.payload.patientUuid,
              e: event.payload.email,
              p: event.payload.phone,
              fn: event.payload.firstName,
              ln: event.payload.lastName,
              g: event.payload.gender.charAt(0).toLowerCase(),
              ct: event.payload.city,
              pc: event.payload.postalCode,
              co: event.payload.country,
            },
          })
          .catch(this.rethrowIfNotDueToAnalyticsNotLoaded);
      });
  }

  private trackUnauthenticatedBsgPageview(
    event: BsgPageviewUnauthenticatedEvent,
  ) {
    this.gtmService
      .pushTag({
        event: 'bsg_pageview',
        page: {
          path: event.payload.path,
        },
        mc_name: event.payload.mc_name,
        ud: {
          l: UNAUTHENTICATED,
        },
      })
      .catch(this.rethrowIfNotDueToAnalyticsNotLoaded);
  }

  private trackSignup(event: SignupEvent) {
    this.sha256Service
      .calculateSHA256HashPromiseObject({
        i: event.payload.userId,
        idsh: event.payload.patientUuid ?? '',
        esh: event.payload.email ?? '',
        psh: event.payload.phone ?? '',
        fnsh: event.payload.firstName,
        lnsh: event.payload.lastName,
        gsh: event.payload.gender.charAt(0).toLowerCase(),
        ctsh: event.payload.city,
        pcsh: event.payload.postalCode,
        cosh: event.payload.country,
      })
      .then((response) => {
        this.gtmService
          .pushTag({
            event: 'sign_up',
            ud: {
              l: AUTHENTICATED,
              ...response,
              id: event.payload.patientUuid ?? '',
              e: event.payload.email ?? '',
              p: event.payload.phone ?? '',
              fn: event.payload.firstName,
              ln: event.payload.lastName,
              g: event.payload.gender?.charAt(0).toLowerCase(),
              ct: event.payload.city,
              pc: event.payload.postalCode,
              co: event.payload.country,
            },
          })
          .catch(this.rethrowIfNotDueToAnalyticsNotLoaded);
      });
  }

  private readonly rethrowIfNotDueToAnalyticsNotLoaded = (err: unknown) => {
    const errorThrownDueToAnalyticsNotLoaded = typeof err === 'undefined';
    if (!errorThrownDueToAnalyticsNotLoaded) {
      throw err;
    }
  };
}
