import { AppEvent } from './app-event';

export const APPOINTMENT_CREATION_EVENT = 'APPOINTMENT_CREATION';

export interface AppointmentCreationEventPayload {
  timestamp: number;
  appointment_timestamp: number;
  appointment_day: string;
  appointment_time: string;
  categoryId: number;
  categoryName: string;
  motiveId: number;
  motiveName: string;
  medicalCenterName: string;
  appointmentId: string;
  userId: string;
  email: string;
  phone: string;
  patientUuid: string;
  firstName: string | null;
  lastName: string | null;
  gender: string | null;
  city: string | null;
  postalCode: string | null;
  country: string | null;
}

export class AppointmentCreationEvent extends AppEvent<AppointmentCreationEventPayload> {
  readonly type: string = APPOINTMENT_CREATION_EVENT;
  readonly payload: AppointmentCreationEventPayload;

  constructor(appointmentInformation: AppointmentCreationEventPayload) {
    super();
    this.payload = appointmentInformation;
  }
}
