import 'intl-tel-input/build/js/utils.js';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IntlTelInputComponent } from 'intl-tel-input-ng';

import { ConfigService } from '../../config.service';
import { MedicalCenterService } from '../../medical-center/service/medical-center.service';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    IntlTelInputComponent,
    ReactiveFormsModule,
  ],
  selector: 'app-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneInputComponent {
  @Input()
  message =
    $localize`A code will be sent to you on this number to validate your account.`;
  @Input({ required: true }) shouldShowErrors: boolean | undefined;
  @Output() valueChanged = new EventEmitter<string>();
  @Output() phoneValidityChanged = new EventEmitter<boolean>();

  value = '';
  allowedCountries = ConfigService.allowedCountriesPhoneList();
  preferredCountryCodes = ConfigService.preferredCountriesPhoneList();
  defaultCountryCode: string = this.medicalCenterService.countryCode$.value;

  constructor(private readonly medicalCenterService: MedicalCenterService) {}

  changePhoneNumber(value: string) {
    this.value = value;
    this.phoneValidityChanged.emit(!value);
    this.valueChanged.emit(value);
  }
}
