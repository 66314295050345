import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';

import { errorCodes } from '../../../authentication/api/error/error-codes';
import { ReferralNotExists } from '../../../authentication/api/error/referral-not-exists';
import { BaseApi } from '../../../base.api';
import { PatientFormRequest } from './model/patient-form-request';
import { PatientResponse } from './model/patient-response';
import { SavePatientDataResponse } from './model/save-patient-data-response';

@Injectable()
export class PatientApi extends BaseApi {
  savePatientData(
    patientData: PatientFormRequest,
  ): Observable<SavePatientDataResponse> {
    const url = this.baseUrl + '/booking/patients';

    return this.httpClient.post<SavePatientDataResponse>(url, patientData).pipe(
      catchError((err: unknown) => {
        if (
          err instanceof HttpErrorResponse &&
          err.error.code === errorCodes.REFERRAL_NOT_FOUND
        ) {
          return throwError(() => new ReferralNotExists());
        }
        return throwError(() => err);
      }),
    );
  }

  getPatient(): Observable<PatientResponse> {
    const url = this.baseUrl + '/booking/patients';

    return this.httpClient.get<PatientResponse>(url);
  }
}
