import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';

import { BaseApi } from '../../base.api';
import { DuplicateEmailError } from './error/duplicate-email-error';
import { EmailOrPasswordNotCorrect } from './error/email-or-password-not-correct';
import { errorCodes } from './error/error-codes';
import { ExpiredPasswordResetCodeError } from './error/expired-password-reset-code-error';
import { ExternalUserNotFoundError } from './error/external-user-not-found-error';
import { InvalidPasswordResetCodeError } from './error/invalid-password-reset-code-error';
import { ReferralNotExists } from './error/referral-not-exists';
import { WaitMinuteBeforeNextLogin } from './error/wait-minute-before-next-login';
import { LoginRequest } from './model/login-request';
import { RegistrationRequest } from './model/registration-request';
import { RegistrationResponse } from './model/registration-response';

@Injectable({ providedIn: 'root' })
export class AuthenticationApi extends BaseApi {
  submitLogin(loginRequest: LoginRequest): Observable<RegistrationResponse> {
    const url = this.baseUrl + '/booking/public/auth/login';

    return this.httpClient.post<RegistrationResponse>(url, loginRequest).pipe(
      catchError((err: unknown) => {
        if (
          err instanceof HttpErrorResponse &&
          err.error.code === errorCodes.WAIT_MINUTE_BEFORE_NEXT_LOGIN
        ) {
          return throwError(() => new WaitMinuteBeforeNextLogin());
        }
        if (
          err instanceof HttpErrorResponse &&
          err.error.code === errorCodes.EMAIL_OR_PASSWORD_NOT_CORRECT
        ) {
          return throwError(() => new EmailOrPasswordNotCorrect());
        }
        return throwError(() => err);
      }),
    );
  }

  submitRegistration(
    registrationRequest: RegistrationRequest,
  ): Observable<RegistrationResponse> {
    const url = this.baseUrl + `/booking/public/auth/register`;

    return this.httpClient
      .post<RegistrationResponse>(url, registrationRequest)
      .pipe(
        catchError((err: unknown) => {
          if (
            err instanceof HttpErrorResponse &&
            err.error.code === errorCodes.EMAIL_ALREADY_TAKEN
          ) {
            return throwError(
              () => new DuplicateEmailError(registrationRequest.email),
            );
          } else if (
            err instanceof HttpErrorResponse &&
            err.error.code === errorCodes.EMAIL_OR_PASSWORD_NOT_CORRECT
          ) {
            return throwError(() => new EmailOrPasswordNotCorrect());
          } else if (
            err instanceof HttpErrorResponse &&
            err.error.code === errorCodes.REFERRAL_NOT_FOUND
          ) {
            return throwError(() => new ReferralNotExists());
          }
          return throwError(() => err);
        }),
      );
  }

  sendPasswordReset(email: string, language: string): Observable<void> {
    const url = this.baseUrl + '/booking/public/auth/reset-password/send';

    return this.httpClient
      .post<void>(
        url,
        { email },
        {
          params: { language },
        },
      )
      .pipe(
        catchError((err: unknown) => {
          if (
            err instanceof HttpErrorResponse &&
            err.error.code === errorCodes.EXTERNAL_USER_NOT_FOUND
          ) {
            return throwError(() => new ExternalUserNotFoundError(email));
          }
          return throwError(() => err);
        }),
      );
  }

  changePassword(
    email: string,
    newPassword: string,
    passwordResetVerificationCode: string,
  ): Observable<void> {
    const url = this.baseUrl + '/booking/public/auth/reset-password';

    return this.httpClient
      .post<void>(url, { email, newPassword, passwordResetVerificationCode })
      .pipe(
        catchError((err: unknown) => {
          if (err instanceof HttpErrorResponse) {
            const errorCode = err.error.code;
            if (errorCode === errorCodes.EXTERNAL_USER_PASSWORD_CODE_EXPIRED) {
              return throwError(
                () =>
                  new ExpiredPasswordResetCodeError(
                    passwordResetVerificationCode,
                  ),
              );
            }
            if (
              errorCode === errorCodes.EXTERNAL_USER_PASSWORD_CODE_NOT_MATCH
            ) {
              return throwError(
                () =>
                  new InvalidPasswordResetCodeError(
                    passwordResetVerificationCode,
                  ),
              );
            }

            if (errorCode === errorCodes.EXTERNAL_USER_NOT_FOUND) {
              return throwError(() => new ExternalUserNotFoundError(email));
            }
          }
          return throwError(() => err);
        }),
      );
  }

  logoutOnBackend(): Observable<void> {
    return this.httpClient.post<void>(
      this.baseUrl + '/booking/public/auth/logout',
      null,
    );
  }
}
