import { Injectable, Type } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { map, race } from 'rxjs';

import { DialogRef } from './dialog-ref';

@Injectable({ providedIn: 'root' })
export class DialogService {
  constructor(private readonly modalService: NgbModal) {}

  open<T>(
    component: Type<T>,
    inputs: Record<string, unknown> = {},
    size?: string,
  ): DialogRef<T> {
    const modalRef = this.modalService.open(component, {
      centered: true,
      modalDialogClass: 'custom-dialog',
      size: size || 'md',
    });
    Object.keys(inputs).forEach(
      (k) => (modalRef.componentInstance[k] = inputs[k]),
    );

    return {
      dialog: modalRef.componentInstance,
      close: modalRef.close.bind(modalRef),
      closed: race([modalRef.closed, modalRef.dismissed]).pipe(
        map(() => undefined),
      ),
    };
  }
}
