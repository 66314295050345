import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  EMPTY,
  Observable,
  Subject,
  takeUntil,
  tap,
  throwError,
} from 'rxjs';

import { DuplicateEmailError } from '../../../authentication/api/error/duplicate-email-error';
import { LoginRequest } from '../../../authentication/api/model/login-request';
import { RegistrationResponse } from '../../../authentication/api/model/registration-response';
import { ResetPasswordDialogComponent } from '../../../authentication/reset-password-dialog/reset-password-dialog.component';
import { AuthenticationService } from '../../../authentication/service/authentication.service';
import { DialogService } from '../../../dialog/service/dialog.service';
import { LanguageService } from '../../../language/service/language.service';
import { AppointmentCreationService } from '../../service/appointment-creation.service';
import { IdentificationFormType } from '../model/identification-form-type.enum';
import { RegistrationForm } from '../model/registration-form';

@Injectable()
export class IdentificationService {
  readonly selectedIdentificationForm$ = new Subject<IdentificationFormType>();
  readonly isLoginDataSubmitted$ =
    this.authenticationService.isLoginDataSubmitted$;
  readonly isRegisterDataSubmitted$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly appointmentCreationService: AppointmentCreationService,
    private readonly dialogService: DialogService,
    private readonly languageService: LanguageService,
  ) {}

  submitRegistration(
    registrationForm: RegistrationForm,
  ): Observable<RegistrationResponse> {
    this.isRegisterDataSubmitted$.next(true);
    const language = this.languageService.getLanguage();
    const registrationRequest = {
      email: registrationForm.email,
      password: registrationForm.password,
      phoneNumber: registrationForm.phoneNumber,
      referralCode: registrationForm.referralCode,
      language: language,
      optInEmail: registrationForm.optInEmail,
    };
    return this.authenticationService
      .submitRegistration(registrationRequest)
      .pipe(
        catchError((err: unknown) => {
          this.isRegisterDataSubmitted$.next(false);
          if (err instanceof DuplicateEmailError) {
            this.displayPasswordResetDialog(registrationForm.email);
            return EMPTY;
          }
          return throwError(() => err);
        }),
        tap(() => this.appointmentCreationService.identityRegistered()),
      );
  }

  submitLogin(data: LoginRequest) {
    return this.authenticationService
      .submitLogin(data)
      .pipe(tap(() => this.appointmentCreationService.identityVerified()));
  }

  openForm(identificationFormType: IdentificationFormType) {
    this.selectedIdentificationForm$.next(identificationFormType);
  }

  private displayPasswordResetDialog(email: string) {
    const dialogRef = this.dialogService.open(ResetPasswordDialogComponent, {
      email,
    });
    dialogRef.dialog.submitted
      .pipe(
        tap(() => {
          this.openForm(IdentificationFormType.LOGIN);
          dialogRef.close();
        }),
        takeUntil(dialogRef.closed),
      )
      .subscribe();
  }
}
