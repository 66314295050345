import { NgModule } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';

import { canActivate as fullAccessGuard } from './appointment-creation/guard/full-access.guard';
import { canActivate as authenticationGuard } from './authentication/guard/authentication.guard';
import { ConfigService } from './config.service';
import { MaintenanceRouter } from './maintenance/router/maintenance.router';
import { canActivate as medicalCenterGuard } from './medical-center/guard/medical-center.guard';
import { PageNotFoundComponent } from './standalone-components/page-not-found/page-not-found.component';

const appModuleRoutes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./appointment-creation/appointment-creation.module').then(
        (m) => m.AppointmentCreationModule,
      ),
    canActivate: [],
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./profile/profile.module').then((m) => m.ProfileModule),
    canActivate: [authenticationGuard, fullAccessGuard],
  },
  {
    path: 'privacy-policy',
    loadComponent: () =>
      import(
        './standalone-components/terms-of-service/privacy-policy/privacy-policy.component'
      ).then((mod) => mod.PrivacyPolicyComponent),
  },
  {
    path: 'terms-and-conditions',
    loadComponent: () =>
      import(
        './standalone-components/terms-of-service/terms-and-conditions/terms-and-conditions.component'
      ).then((mod) => mod.TermsAndConditionsComponent),
  },
  {
    path: 'page-not-found',
    component: PageNotFoundComponent,
    pathMatch: 'full',
  },
  {
    path: 'verification',
    loadChildren: () =>
      import('./verification/verification.module').then(
        (m) => m.VerificationModule,
      ),
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationModule,
      ),
  },
  {
    path: 'reset-password', // path that's set in reset password email
    redirectTo: 'auth/reset-password',
  },
  {
    path: 'document',
    loadChildren: () =>
      import('./document-signing/document-signing.module').then(
        (m) => m.DocumentSigningModule,
      ),
    canActivate: [medicalCenterGuard],
  },
  {
    path: 'register',
    loadComponent: () =>
      import(
        './standalone-components/registration-form/registration-form.component'
      ).then((mod) => mod.RegistrationFormComponent),
  },
  {
    path: 'locations',
    loadChildren: () =>
      import('./store-locator/store-locator.module').then(
        (m) => m.StoreLocatorModule,
      ),
  },
  {
    path: 's',
    loadChildren: () =>
      import('./url-redirection/url-redirection.module').then(
        (m) => m.UrlRedirectionModule,
      ),
  },
  {
    path: '**',
    redirectTo: 'page-not-found',
  },
];

const routes: Routes = !ConfigService.isMaintenanceModeActive()
  ? appModuleRoutes
  : [
      {
        path: ConfigService.getMaintenanceModeActivePath().slice(1),
        children: appModuleRoutes,
      },
      {
        path: '',
        redirectTo: 'maintenance',
        pathMatch: 'full',
      },
      {
        path: '**',
        redirectTo: 'maintenance',
        pathMatch: 'full',
      },
      {
        path: 'maintenance',
        loadChildren: () =>
          import('./maintenance/maintenance.module').then(
            (m) => m.MaintenanceModule,
          ),
      },
    ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    {
      provide: Router,
      useClass: ConfigService.isMaintenanceModeActive()
        ? MaintenanceRouter
        : Router,
    },
  ],
})
export class AppRoutingModule {}
