import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'app-reset-password-dialog',
  templateUrl: './reset-password-dialog.component.html',
  styleUrls: ['./reset-password-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetPasswordDialogComponent implements OnInit {
  @Input({ required: true })
  email: string | null = null;

  @Output()
  submitted = new EventEmitter();

  errorMessage$ = new ReplaySubject<string>(1);

  ngOnInit() {
    this.errorMessage$.next(
      $localize`An account with the email ${this.email} exists already`,
    );
  }
}
