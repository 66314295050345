import {
  captureConsoleIntegration,
  init,
  replayIntegration,
} from '@sentry/browser';

import { ConfigService } from './app/config.service';

const integrations = [
  captureConsoleIntegration({
    levels: ['error'],
  }),
];
if (ConfigService.isProd()) {
  integrations.push(
    replayIntegration({
      networkDetailAllowUrls: [
        '/api/booking/appointments',
        '/api-portal/booking/public/availability',
        '/api/booking/verification',
        '/api/booking/patients',
        '/api/booking/profile/patient',
        '/api/booking/public/medical-centers',
      ],
      networkCaptureBodies: true,
    }),
  );
}

init({
  dsn: ConfigService.sentryDsn(),
  environment: 'ng-' + ConfigService.currentEnvironment(),
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations,
  normalizeDepth: 7,
  attachStacktrace: true,
});
