import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { EventDispatcher } from '../../event-dispatcher/event-dispatcher';
import { ValidateTimeslotEvent } from '../../event-dispatcher/events/validate-timeslot-event';
import { NavigationService } from '../../navigation/service/navigation.service';
import { AppointmentInformation } from '../model/appointment-information';

@Injectable()
export class AppointmentCreationService {
  selectedAppointmentInformation$: BehaviorSubject<AppointmentInformation | null> =
    new BehaviorSubject<AppointmentInformation | null>(null);
  readonly currentStep$ = new BehaviorSubject(1);

  constructor(
    private readonly navigationService: NavigationService,
    private readonly eventDispatcher: EventDispatcher,
  ) {}

  appointmentSelectedAndValidated(
    appointmentInformation: AppointmentInformation,
  ) {
    this.selectedAppointmentInformation$.next(appointmentInformation);
    this.eventDispatcher.dispatch(
      new ValidateTimeslotEvent({
        categoryId: appointmentInformation.categoryId,
        categoryName: appointmentInformation.categoryName,
        motiveId: appointmentInformation.motiveId,
        motiveName: appointmentInformation.motiveName,
        medicalCenterName: appointmentInformation.medicalCenter?.name ?? '',
      }),
    );
    localStorage.getItem('token')
      ? this.identityVerified()
      : this.motiveSelected();
  }

  motiveSelected() {
    this.currentStep$.next(2);
    this.navigationService.navigateTo('/identification');
  }

  identityVerified() {
    this.currentStep$.next(3);
    this.navigationService.navigateTo('/confirmation');
  }

  identityRegistered() {
    this.currentStep$.next(3);
    this.navigationService.navigateWithRedirectionPathAndWithoutMedicalCenter(
      '/verification/phone',
      '/confirmation',
    );
  }

  bookingProcessStarted() {
    this.currentStep$.next(1);
    this.selectedAppointmentInformation$.next(null);
  }
}
